import React, { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoIcon from '@mui/icons-material/Info';
import '../scrollbar.css';
import { Accordion, AccordionDetails, AccordionSummary, Box, Card, CardActionArea, CardContent, CssBaseline, Grid, IconButton, Link, Tooltip, Typography, Popover, Paper, Button } from '@mui/material';

// Utility function to detect mobile devices
const isMobileDevice = () => /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

function Resources() {
    const [view, setView] = useState('resources'); // 'resources' or 'categoryDescription'

    const panelContents = [
        {
            title: 'Medical and Behavioral Support',
            tooltip: 'Resources in this category are tailored for direct clinical treatment and therapeutic support, provided by certified doctors, therapists, and counselors. They focus on delivering structured interventions, mental health assessments, diagnosis, and tailored treatment plans. This includes: Psychiatrists, Clinical Psychologists and Counselors, Behavioral Therapists, and Pediatricians.',
            cards: [
                { title: 'Pediatric Mental Health', description: 'Access comprehensive health resources tailored for children.', url: '/resourceplaceholder' },
                { title: 'Child Psychology Services', description: 'Connect with professional child psychologists for early intervention.', url: '/resourceplaceholder' },
                { title: 'Behavioral Therapy for Children', description: 'Explore therapy and counseling services for behavioral challenges.', url: '/resourceplaceholder' }
            ]
        },
        {
            title: 'Technology Based Solution',
            tooltip: 'This category includes digital tools and resources designed to support mental health through technology. These may feature: Therapeutic Mobile Apps, Educational Games, and  Online Therapy Platforms.',
            cards: [
                { title: 'Therapeutic Apps for Children', description: 'Discover apps designed to help children manage anxiety and stress.', url: '/resourceplaceholder' },
                { title: 'Educational Games for Mental Health', description: 'Engage your child with games that promote emotional well-being.', url: '/resourceplaceholder' },
                { title: 'Online Support Platforms', description: 'Find online platforms offering support groups and resources for children and parents.', url: '/resourceplaceholder' }
            ]
        },
        {
            title: 'Educational Support',
            tooltip: 'This category provides resources that support children with special learning needs or mental health challenges in school and home settings. Additionally, it includes educational resources aimed at empowering parents with knowledge about specific conditions, enabling them to understand and support their child’s unique needs effectively. Resources include: Special Education Services, Parental Education on Mental Health Conditions, Support Networks, Workshops and Educational Sessions on Child Mental Health.',
            cards: [
                { title: 'Special Education Resources', description: 'Resources for parents and teachers of children with special educational needs.', url: '/resourceplaceholder' },
                { title: 'Learning Disabilities Support', description: 'Access tools and communities for learning disabilities.', url: '/resourceplaceholder' },
                { title: 'Educational Workshops on Child Mental Health', description: 'Participate in workshops designed to educate on child mental health issues.', url: '/resourceplaceholder' }
            ]
        },
        {
            title: 'Coaching and Organizational Support',
            tooltip: 'This category focuses on personal coaching and organizational skills development to support a child’s mental and emotional growth. Includes: Parent Coaching, Child Development Coaching, and Organizational Skill-Building Programs.',
            cards: [
                { title: 'Parent Coaching for Mental Health', description: 'Gain insights and strategies for supporting your child’s mental health.', url: '/resourceplaceholder' },
                { title: 'Child Development Coaching', description: 'Professional guidance on fostering mental and emotional development.', url: '/resourceplaceholder' },
                { title: 'Organizational Skills for Children', description: 'Tools and strategies to help children organize and reduce stress.', url: '/resourceplaceholder' }
            ]
        },
        {
            title: 'Lifestyle and Self-Help',
            tooltip: 'These resources are self-guided programs and lifestyle-focused initiatives aimed at helping children build resilience and manage daily stress. Includes: Mindfulness Programs, Healthy Lifestyle Programs, Self-Help Techniques.',
            cards: [
                { title: 'Mindfulness for Children', description: 'Learn about mindfulness practices suited for children.', url: '/resourceplaceholder' },
                { title: 'Healthy Lifestyle Programs', description: 'Programs designed to promote a healthy lifestyle among children.', url: '/resourceplaceholder' },
                { title: 'Self-Help Strategies for Youngsters', description: 'Simple self-help techniques that children can practice.', url: '/resourceplaceholder' }
            ]
        },
        {
            title: 'Community and Social Support',
            tooltip: 'Social resources that foster a sense of community, connection, and support among children and families facing similar challenges. Resources include: Community Mental Health Programs, Social Skills Groups, and Volunteer Opportunities.',
            cards: [
                { title: 'Community Mental Health Programs', description: 'Join community-based programs focused on child mental health.', url: '/resourceplaceholder' },
                { title: 'Social Skills Groups for Children', description: 'Connect your child with groups that enhance social skills and emotional intelligence.', url: '/resourceplaceholder' },
                { title: 'Volunteer for Child Mental Health', description: 'Opportunities to volunteer in organizations focused on child mental health.', url: '/resourceplaceholder' }
            ]
        }
    ];

    const [expandedPanels, setExpandedPanels] = useState(new Map(panelContents.map((panel) => [panel.title, false])));
    const [anchorEl, setAnchorEl] = useState(null);
    const [popoverContent, setPopoverContent] = useState('');

    const handleChange = (panel) => (event, isExpanded) => {
        setExpandedPanels(new Map(expandedPanels.set(panel, isExpanded)));
    };

    const handleIconClick = (event, content) => {
        if (isMobileDevice()) {
            setAnchorEl(event.currentTarget);
            setPopoverContent(content);
        }
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
        setPopoverContent('');
    };

    const open = Boolean(anchorEl);

    if (view === 'categoryDescription') {
        return (
            <CategoryDescription onBack={() => setView('resources')} />
        );
    }

    return (
        <div>
            <CssBaseline />

            {/* Introductory Section */}
            <Box sx={{ marginBottom: 2, padding: 2, backgroundColor: '#e0e0e0', borderRadius: '10px' }}>
                <Typography variant="body1" sx={{ marginBottom: 1 }}>
                    You can use the <InfoIcon fontSize="small" color="action" /> icon to learn about each resource category. Find more in-depth information,
                    {' '}
                    <Link
                        href="#"
                        onClick={(e) => {
                            e.preventDefault();
                            setView('categoryDescription');
                        }}
                        sx={{ textDecoration: 'underline', marginLeft: '4px' }}
                    >
                        here
                    </Link>.
                </Typography>
            </Box>

            {/* Main Resource Container */}
            <Box sx={{ bgcolor: '#A8C0D0', padding: 2, borderRadius: '15px' }}>
                <Grid container spacing={2}>
                    {panelContents.map((panel, index) => (
                        <Grid item xs={12} sm={12} md={12} key={index}>
                            <Accordion
                                expanded={expandedPanels.get(panel.title)}
                                onChange={handleChange(panel.title)}
                                sx={{ backgroundColor: '#A8C0D0', boxShadow: 'none' }}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls={`panel${index}a-content`}
                                    id={`panel${index}a-header`}
                                    sx={{
                                        backgroundColor: '#e0e0e0',
                                        borderRadius: '25px',
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}
                                >
                                    {isMobileDevice() ? (
                                        <IconButton
                                            onClick={(e) => {
                                                e.stopPropagation(); // Prevent accordion from toggling
                                                handleIconClick(e, panel.tooltip);
                                            }}
                                            sx={{ marginRight: '8px' }}
                                        >
                                            <InfoIcon fontSize="small" />
                                        </IconButton>
                                    ) : (
                                        <Tooltip title={panel.tooltip} arrow>
                                            <IconButton
                                                onClick={(e) => e.stopPropagation()} // Prevent accordion from toggling on desktop as well
                                                sx={{ marginRight: '8px' }}
                                            >
                                                <InfoIcon fontSize="small" />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                    <Typography
                                        sx={{
                                            flexGrow: 1,
                                            display: 'flex',
                                            alignItems: 'center',
                                            paddingTop: '1px'
                                        }}
                                    >
                                        {panel.title}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {panel.cards.map((card, idx) => (
                                        <Card
                                            key={idx}
                                            sx={{
                                                width: '100%',
                                                marginBottom: 2,
                                                backgroundColor: '#e0e0e0',
                                                borderRadius: '10px',
                                            }}
                                        >
                                            <CardActionArea component="a" href={card.url} target="_blank" rel="noopener noreferrer">
                                                <CardContent>
                                                    <Typography gutterBottom variant="h6" sx={{ color: '#000000' }}>
                                                        {card.title}
                                                    </Typography>
                                                    <Typography variant="body2" sx={{ color: '#000000' }}>
                                                        {card.description}
                                                    </Typography>
                                                </CardContent>
                                            </CardActionArea>
                                        </Card>
                                    ))}
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                    ))}
                </Grid>
            </Box>

            {/* Popover for mobile devices */}
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Box sx={{ p: 2, maxWidth: 300 }}>
                    <Typography>{popoverContent}</Typography>
                </Box>
            </Popover>
        </div>
    );
}

function CategoryDescription({ onBack }) {
    return (
        <Box sx={{ padding: 2, maxWidth: '35rem', margin: 'auto' }}>
            <Paper elevation={3} sx={{
                bgcolor: '#e0e0e0',
                padding: { xs: 1, sm: 2 },
                margin: 'auto',
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                height: '100%',
                borderRadius: '5px',
                overflowY: 'auto',
            }}>
                <Box sx={{ padding: 2 }}>
                    {/* Medical and Behavioral Support */}
                    <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: 1 }}>
                        Medical and Behavioral Support
                    </Typography>
                    <Typography variant="body2" paragraph>
                        Resources in this category are tailored for direct clinical treatment and therapeutic support, provided by certified doctors, therapists, and counselors. They focus on delivering structured interventions, mental health assessments, diagnosis, and tailored treatment plans. This includes:
                    </Typography>
                    <Typography component="ul" variant="body2" sx={{ pl: 2 }}>
                        <li>Psychiatrists for diagnostic assessments, medication management, and ongoing monitoring.</li>
                        <li>Clinical Psychologists and Counselors who provide psychotherapy, cognitive-behavioral therapy (CBT), and other evidence-based treatments for children facing emotional or behavioral challenges.</li>
                        <li>Behavioral Therapists who specialize in addressing behavioral issues through structured, personalized therapy sessions.</li>
                        <li>Pediatricians focused on early mental health interventions and referrals to mental health specialists.</li>
                    </Typography>

                    {/* Technology-Based Solutions */}
                    <Typography variant="h6" sx={{ fontWeight: 'bold', marginTop: 2, marginBottom: 1 }}>
                        Technology-Based Solutions
                    </Typography>
                    <Typography variant="body2" paragraph>
                        This category includes digital tools and resources designed to support mental health through technology. These may feature:
                    </Typography>
                    <Typography component="ul" variant="body2" sx={{ pl: 2 }}>
                        <li>Therapeutic Mobile Apps that guide children through mindfulness exercises, mood tracking, and stress management techniques.</li>
                        <li>Educational Games aimed at enhancing emotional intelligence, self-regulation, and coping skills in children.</li>
                        <li>Online Therapy Platforms that offer virtual counseling sessions and support groups for children and their families.</li>
                    </Typography>

                    {/* Educational Support */}
                    <Typography variant="h6" sx={{ fontWeight: 'bold', marginTop: 2, marginBottom: 1 }}>
                        Educational Support
                    </Typography>
                    <Typography variant="body2" paragraph>
                        This category provides resources that support children with special learning needs or mental health challenges in school and home settings. Additionally, it includes educational resources aimed at empowering parents with knowledge about specific conditions, enabling them to understand and support their child’s unique needs effectively. Resources include:
                    </Typography>
                    <Typography component="ul" variant="body2" sx={{ pl: 2 }}>
                        <li>Special Education Services offering personalized learning strategies, accommodations, and resources tailored to each child’s needs.</li>
                        <li>Parental Education on Mental Health Conditions, providing workshops, courses, and materials for parents to learn about conditions such as ADHD, autism, and anxiety, and how these can impact a child's learning and behavior.</li>
                        <li>Support Networks for Learning Disabilities that connect parents with tools, communities, and expert advice for navigating learning disabilities.</li>
                        <li>Workshops and Educational Sessions on Child Mental Health to guide parents, teachers, and caregivers in managing and supporting children's mental health challenges in educational and social environments.</li>
                    </Typography>

                    {/* Coaching and Organizational Support */}
                    <Typography variant="h6" sx={{ fontWeight: 'bold', marginTop: 2, marginBottom: 1 }}>
                        Coaching and Organizational Support
                    </Typography>
                    <Typography variant="body2" paragraph>
                        This category focuses on personal coaching and organizational skills development to support a child’s mental and emotional growth. Includes:
                    </Typography>
                    <Typography component="ul" variant="body2" sx={{ pl: 2 }}>
                        <li>Parent Coaching to provide parents with strategies for supporting their child's mental health journey.</li>
                        <li>Child Development Coaching focused on emotional and cognitive skills that foster resilience and healthy growth.</li>
                        <li>Organizational Skill-Building Programs for children to develop time-management, planning, and other executive functioning skills that reduce stress.</li>
                    </Typography>

                    {/* Lifestyle and Self-Help */}
                    <Typography variant="h6" sx={{ fontWeight: 'bold', marginTop: 2, marginBottom: 1 }}>
                        Lifestyle and Self-Help
                    </Typography>
                    <Typography variant="body2" paragraph>
                        These resources are self-guided programs and lifestyle-focused initiatives aimed at helping children build resilience and manage daily stress. Includes:
                    </Typography>
                    <Typography component="ul" variant="body2" sx={{ pl: 2 }}>
                        <li>Mindfulness Programs for children to practice relaxation and mindfulness techniques.</li>
                        <li>Healthy Lifestyle Programs that encourage physical wellness, balanced nutrition, and stress management.</li>
                        <li>Self-Help Techniques designed for children to practice independently, such as journaling, gratitude exercises, and setting personal goals.</li>
                    </Typography>

                    {/* Community and Social Support */}
                    <Typography variant="h6" sx={{ fontWeight: 'bold', marginTop: 2, marginBottom: 1 }}>
                        Community and Social Support
                    </Typography>
                    <Typography variant="body2" paragraph>
                        Social resources that foster a sense of community, connection, and support among children and families facing similar challenges. Resources include:
                    </Typography>
                    <Typography component="ul" variant="body2" sx={{ pl: 2 }}>
                        <li>Community Mental Health Programs to connect families with local mental health resources and support groups.</li>
                        <li>Social Skills Groups designed to improve communication, empathy, and emotional intelligence.</li>
                        <li>Volunteer Opportunities for families and children interested in contributing to child mental health awareness and support networks.</li>
                    </Typography>
                </Box>

                {/* Back Button */}
                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
                    <Button
                        variant="contained"
                        onClick={onBack}
                        sx={{
                            backgroundColor: '#607D8B',
                            borderColor: '#000000',
                            borderWidth: 2,
                            borderStyle: 'solid',
                            borderRadius: '16px',
                            '&:hover': {
                                backgroundColor: '#546E7A',
                            },
                        }}
                    >
                        Back
                    </Button>
                </Box>
            </Paper>
        </Box>
    );
}

export default Resources;